<template>
    <Layout>
        <PageHeader
            :items="items"
            :title="title"
            :link="`/${this.$route.params.slug}/dashboard/final-test`"
        />

        <div class="pageContent backgroundZircon testWrapper">
            <div class="comparisonResultWrapper">
                <div
                    v-for="quiz in quizes"
                    :key="quiz.type"
                    class="profileBox profileBox--2 comparisonResultBox"
                >
                    <div class="testResult">
                        <div class="testResult__title">
                            <p class="profileBox__title2">
                                Тест пройден!
                            </p>
                            <p class="profileBox__text2">
                                <span v-if="quiz.date">
                                    <span v-if="quiz.type === 'entrance_test'"
                                        >Вступительный тест Вы сдавали</span
                                    >
                                    <span v-else-if="quiz.type === 'final_test'"
                                        >Вы сдали финальный тест</span
                                    >
                                    <br />
                                    {{ quiz.date }}
                                </span>
                                <span
                                    v-if="quiz.type === 'final_test' && progressScore > 0"
                                    >и улучшили свои<br />
                                    показатели на {{ progressScore }}%.</span
                                >
                            </p>
                        </div>
                        <div class="testResult__correctAnswers">
                            <p class="h1">
                                {{ quiz.countCorrectAnswers }}
                                <span class="colorGray">
                                    из {{ quiz.questions.length }}
                                </span>
                            </p>
                            <p class="colorGray testResult__correctAnswers_text">
                                правильных ответов
                            </p>
                        </div>
                    </div>

                    <div v-for="question in quiz.questions" :key="question.questionId">
                        <p class="profileBox__title2 profileBox__title2--3">
                            {{ question.description }}
                        </p>
                        <div class="form form--2 testResultForm">
                            <div
                                v-for="answer in question.answers"
                                :key="answer._id"
                                class="formgroup"
                            >
                                <label
                                    :class="
                                        `formgroup__radioLabel ${
                                            answer.answeredByMember
                                                ? answer.isCorrect
                                                    ? 'formgroup__radioLabel--success'
                                                    : 'formgroup__radioLabel--wrong'
                                                : 'formgroup__radioLabel'
                                        }`
                                    "
                                >
                                    <span v-if="answer.answeredByMember">
                                        <img
                                            v-if="answer.isCorrect"
                                            class="formgroup__radioLabel_img"
                                            alt="done"
                                            src="@/assets/img/pages/study/done.svg"
                                        />
                                        <img
                                            v-else
                                            class="formgroup__radioLabel_img"
                                            alt="done"
                                            src="@/assets/img/pages/study/wrong.svg"
                                        />
                                    </span>
                                    <img
                                        v-if="answer.isCorrect"
                                        class="formgroup__radioLabel_img"
                                        alt="done"
                                        src="@/assets/img/pages/study/done.svg"
                                    />
                                    <input
                                        class="input--checkbox"
                                        type="radio"
                                        disabled
                                    />
                                    <span>{{ answer.text }}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Button
                class="comparisonResultButton"
                title="перейти На дашборд"
                :isLink="true"
                :link="`/${$route.params.slug}/dashboard`"
            />
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import PageHeader from "@/views/partials/pageHeader/pageHeader";
import Button from "@/views/components/Button/Button";

export default {
    name: "comparisonResult",
    components: {
        Layout,
        PageHeader,
        Button,
    },
    props: {},

    watch: {
        quizes: function() {
            if (this.quizes.length === 2) {
                this.progressScore = Math.round(
                    (this.quizes[1].countCorrectAnswers /
                        (this.quizes[1].countCorrectAnswers +
                            this.quizes[1].countIncorrectAnswers) -
                        this.quizes[0].countCorrectAnswers /
                            (this.quizes[0].countCorrectAnswers +
                                this.quizes[0].countIncorrectAnswers)) *
                        100
                );
            }
        },
    },

    computed: {
        member: function() {
            return (
                (this.$store.getters.getMember && this.$store.getters.getMember.data) ||
                {}
            );
        },

        isPassed: function() {
            return {
                entrance_test:
                    (this.member &&
                        this.member.passedEntranceTest &&
                        this.member.passedEntranceTest.isPassed) ||
                    false,
                final_test: (this.member && !this.member.showFinalQuiz) || false,
            };
        },
    },

    async beforeMount() {
        await this.updateMemberStore();
        await this.updateQuizResultsStore("entrance_test");
        await this.updateQuizResultsStore("final_test");

        if (!this.isPassed.entrance_test && !this.isPassed.final_test) {
            await this.$router.push("/" + this.$route.params.slug + "/dashboard");
        }
    },

    data() {
        return {
            title: "Сравнение результатов",
            items: [
                {
                    text: "личный дашборд",
                    to: "/" + this.$route.params.slug + "/dashboard",
                },
                {
                    text: "Сравнение результатов",
                    active: true,
                },
            ],

            quizes: [],
            progressScore: 0,
        };
    },
    methods: {
        async updateMemberStore() {
            const getMemberData = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study"
            );
            if (getMemberData && getMemberData.data && getMemberData.data.result) {
                if (getMemberData.data.code === "programs") {
                    await this.$store.dispatch("clearMember");
                    await this.$router.push("/");
                } else {
                    await this.$store.dispatch("setMember", {
                        data: getMemberData.data,
                    });
                }
            } else {
                console.error(getMemberData.data.error);
                await this.$store.dispatch("clearMember");
                await this.$router.push("/");
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text:
                            getMemberData.data.error === "Пользователь не найден"
                                ? "В момент заморозки личный кабинет недоступен, но после ее окончания Вы с лёгкостью сможете вернуться в кабинет и продолжить обучение"
                                : getMemberData.data.error,
                    },
                });
            }
        },

        async updateQuizResultsStore(group) {
            const getQuizData = await this.axios.get(
                "/programs/" +
                    this.$route.params.slug +
                    "/quiz-course/answers?group=" +
                    group
            );

            if (getQuizData && getQuizData.data && getQuizData.data.result) {
                this.quizes.push(getQuizData.data);
            } else {
                if (getQuizData && getQuizData.data && getQuizData.data.message)
                    console.error(getQuizData.data.message);
            }
        },
    },
};
</script>

<style lang="scss">
@import "./comparisonResult.scss";
</style>
